<template>
  <div class="btn audio-play">
    <b-form-checkbox switch size="lg" v-model="audioPlay"></b-form-checkbox>
    <span class="text">{{ $t("store.audio")}}</span>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "audioPlay",
  data() {
    return {
      AudioPlayKey: 'sound',
      audioPlay: true,
    };
  },
  methods:{
    ...mapMutations(['_setIsAudio']),
  },
  mounted: function () {
  const audioPlay = localStorage.getItem(this.AudioPlayKey);
  if (audioPlay !== null) {
    this.audioPlay = audioPlay === 'true';
    this._setIsAudio(this.audioPlay);
  }
},
  watch: {
    audioPlay (value) {
      if (value == this.IsAudio)
        return ;
      this._setIsAudio(value);
      localStorage.setItem(this.AudioPlayKey, value);
    },
  },
  computed: {
    ...mapState(['WebSetting','IsAudio']),
  }
};
</script>
