<template>
  <div>
    <b-modal
      id="modalTransferPoint"
      ref="myModalRef"
      :modal-class="myclass"
      hide-footer
      hide-header
      @hidden="onClose"
    >
      <div class="modal-header">
        <div class="circle"><i class="icon icon-turnpoint"></i></div>
        <h5 class="title">{{ $t("store.transfer.donate_point_member") }}</h5>
      </div>

      <template>
          <p class="description">{{ $t("store.transfer.turning_reduce_fees") }} {{ PointFeePercentage }} % + {{ PointFeeSingle | currencyInt }}</p>
          <div class="money-box">
            <div class="item">
              <div class="label">{{ $t("store.transfer.have_coin") }}</div>
              <div class="before-transfer"><b-spinner
                variant="variant"
                type="grow"
                small
                v-if="AccountWalletMainLoading"
                label="Spinning"
              ></b-spinner>
              <span v-else>{{ AccountWalletMain | currencyInt }}</span></div>
            </div>
            <div class="item">
              <div class="label">{{ $t("store.transfer.maximum_coin") }}</div>
              <div class="can-transfer">{{ PointMax | currencyInt }}</div>
            </div>
            <div class="item">
              <div class="label">{{ $t("store.transfer.minimum_coin") }}</div>
              <div class="can-transfer">{{ PointMin | currencyInt }}</div>
            </div>
          </div>
      </template>
      
      <b-form @submit.stop.prevent="onSubmit">
        <div class="modal-main">
          <b-form-group :label="$t('store.transfer.donate_point_account')">
            <b-form-input
              v-model="$v.form.account.$model"
              :state="validateState('account')"
              :placeholder="$t('store.plz_enter_donate_points')"
              required
              trim
            />
            <b-form-invalid-feedback>{{
              $t("store.tip_donate_cant_required")
            }}</b-form-invalid-feedback>
          </b-form-group>

          <b-form-group :label="$t('store.transfer.donate_points')">
            <b-form-input
              v-model="$v.form.point.$model"
              :state="validateState('point')"
              :placeholder="$t('store.plz_enter_points_num')"
              required
              trim
            />
            <b-form-invalid-feedback>{{
              $t("store.transfer.donate_points_limit") +
                ` ${PointMin} ~ ${PointMax}`
            }}</b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            :label="$t('store.pw.safe_code')"
            class="chgsafepw"
          >
            <b-form-select
              v-model="form.safe_password[0]"
              :options="selectOptions"
            ></b-form-select>
            <b-form-select
              v-model="form.safe_password[1]"
              :options="selectOptions"
            ></b-form-select>
            <b-form-select
              v-model="form.safe_password[2]"
              :options="selectOptions"
            ></b-form-select>
            <b-form-select
              v-model="form.safe_password[3]"
              :options="selectOptions"
            ></b-form-select>
            <b-form-invalid-feedback>{{
              $t("store.pw.safe_pw_feedback")
            }}</b-form-invalid-feedback>
          </b-form-group>

          <b-form-group :label="$t('store.btn.CAPTCHA')">
            <b-form-input
              v-model="$v.form.captchas.$model"
              :state="validateState('captchas')"
              :placeholder="$t('store.plz_enter_CAPTCHA')"
              required
              trim
            />
            <b-form-invalid-feedback>{{
              $t("store.tip_captchas_required")
            }}</b-form-invalid-feedback>
            <div class="input-group-prepend">
              <img
                :src="TransferCaptchasImg"
                @click="_changeTransferCaptchas"
              />
            </div>
          </b-form-group>
        </div>

        <div class="modal-footer col-12">
          <b-button
            block
            pill
            class="btn-cancel"
            variant="cancel"
            @click="onClose"
          >
            {{ $t("store.btn.cancel") }}
          </b-button>
          <b-button
            block
            pill
            class="btn-turnpoint"
            type="submit"
            variant="submit"
          >
            {{ $t("store.btn.turnpoint") }}
          </b-button>
        </div>

        <b-overlay :show="isSubmit" no-wrap>
          <template v-slot:overlay>
            <div class="loading text-center">
              <div class="lds-square"></div>
              <div class="text">Loading...</div>
            </div>
          </template>
        </b-overlay>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions, mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required, numeric, minValue, between } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  components: {},
  data() {
    return {
      myclass: ["dialog-TransferPoint"],
      groupclass: ["badge-group"],
      isSubmit: false,
      AccountWalletMain: 0,
      AccountWalletMainLoading: false,
      form: {
        account: null,
        safe_password: [0, 0, 0, 0],
        point: 0,
        captchas: null,
      },
    };
  },
  validations() {
    let form = {
      account: { required },
      safe_password: { required },
      point: { required, numeric, minValue: minValue(1), between: between(this.PointMin, this.PointMax)},
      captchas: { required },
    };
    return { form };
  },
  created() {
    if (Object.keys(this.AccountWallet).indexOf("0") >= 0)
        this.OnAccountWalletChanged();
      if (Object.keys(this.AccountWalletLoading).indexOf("0") >= 0)
        this.OnAccountWalletLoadingChanged();

      this.$watch(() => this.AccountWallet[0], this.OnAccountWalletChanged);
      this.$watch(
        () => this.AccountWalletLoading[0],
        this.OnAccountWalletLoadingChanged
      );
  },
  methods: {
    showModal: function () {
      this.$refs.myModalRef.show();
    },
    hideModal() {
      this.$refs.myModalRef.hide();
    },
    RefreshAccountWallet() {
      this.GetAccountWallet(0);
    },
    OnAccountWalletChanged() {
      this.AccountWalletMain = this.AccountWallet[0];
    },
    OnAccountWalletLoadingChanged() {
      this.AccountWalletMainLoading = this.AccountWalletLoading[0];
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit(evt) {
      evt.preventDefault();

      this.$v.form.$touch();
      if (this.$v.form.$anyError) return;
      if (this.isSubmit) return;

      this.isSubmit = true;
      this.AccountConversion(this.form).then((res) => {
        if (res) this.onReset();
        this.GetAccountWallet(0);
        this._changeTransferCaptchas();
        this.isSubmit = false;
      });
    },
    onReset() {
      this.form = {
        account: null,
        safe_password: [0, 0, 0, 0],
        point: 0,
        captchas: null
      };
      this.$nextTick(() => this.$v.$reset());
    },
    onClose() {
      this.onReset();
      this.$root.$emit("bv::hide::modal", "modalTransferPoint", "#btnToggle");
    },
    changeLang() {
    },
    ...mapActions("account", ["GetAccountWallet"]),
    ...mapActions("bank", ["_changeTransferCaptchas", "_getConversionSettingOptions", "AccountConversion"]),
  },
  mounted() {
    this.changeLang();
    this._changeTransferCaptchas();
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (modalId == 'modalTransferPoint')
        this._getConversionSettingOptions();
    })
  },
  watch: {
    "$i18n.locale"(language) {
      this.changeLang();
    },
  },
  computed: {
    selectOptions() {
      let options = [];
      for (var i = 0; i <= 9; i++) {
        options.push({ value: i, text: i });
      }
      return options;
    },
    PointFeePercentage() {
      return this.AccountConversionData.fee_percentage;
    },
    PointFeeSingle() {
      return this.AccountConversionData.fee_single;
    },
    PointMin() {
      return this.AccountConversionData.min || 1;
    },
    PointMax() {
      return this.AccountConversionData.max || 100;
    },  
    ...mapState(["WebSetting"]),
    ...mapState("bank", ["TransferCaptchasImg", 'AccountConversionData','AccountDiscountData']),
    ...mapState("account", ["AccountWallet", "AccountWalletLoading"]),
  },
};
</script>