<template>
</template>

<script>
  import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
  export default {
    created() {
      if (Object.keys(this.$route.query).indexOf('a') >= 0)
        this._getUpAgentCode({ code: this.$route.query.a });
    },
    mounted() {
      if (Object.keys(this.$route.query).indexOf('act') >= 0 && this.$route.query.act == 'i') {
        if (Object.keys(this.$route.query).indexOf('c') >= 0) {
          this._getUpAgentCode({ code: this.$route.query.c }).then(() => {
            this.$router.push({ name: (this.IsMobile ? 'mobile' : 'index'), params: undefined, query: undefined });
          })
        }
      }

      if (this.CheckUpAgentCode || Object.keys(this.$route.query).indexOf("target") >= 0) {
        this._setGoToJoin(false);
        this.$nextTick(function () {
          this.$router.push({ name: (this.IsMobile ? 'mobile-' : '') + 'account-signup', params: undefined, query: undefined });
        });
      }
    },
    methods: {
      ...mapActions('account', ['_getUpAgentCode']),
      ...mapMutations(["_setGoToJoin", "_setUpAgentCode"]),
    },
    computed: {
      CheckUpAgentCode() {
        let Code = null;
        let CookiesCode = this.$cookies.get("UpAgentCode");
        let StorageCode = typeof window !== 'undefined' ? localStorage.getItem('UpAgentCode') : null;

        if (this.UpAgentCode && this.UpAgentCode != null && this.UpAgentCode != '')
          Code = this.UpAgentCode;
        if (CookiesCode && CookiesCode != null && CookiesCode != '')
          Code = CookiesCode;
        if (StorageCode && StorageCode != null && StorageCode != '')
          Code = StorageCode;

        if (Code != this.UpAgentCode)
          this._setUpAgentCode(Code);

        if (!Code || !this.GoToJoin)
          return false;

        return true;
      },
      ...mapState(['IsMobile', 'UpAgentCode', 'GoToJoin'])
    },
    watch: {
      UpAgentCode (UpAgentCode) {
        if ( typeof window === 'undefined')
          return '';

        if (!UpAgentCode || UpAgentCode == null || UpAgentCode == '')
          return '';

        localStorage.removeItem('UpAgentCode')
        localStorage.setItem('UpAgentCode', UpAgentCode);
      }
    }
  };
</script>
