<template>
  <b-button variant="primary" @click="allAccountTransfer"><span>{{ $t("store.wallet.back_wallet") }}</span></b-button>
</template>
<script>
  import { mapState, mapActions, mapMutations } from "vuex";
  import { getJsonFile } from '~/utils/jsonFile';
  export default {
    name: "allBackWalletBtn",
    data() {
      return {
        form: { from_id: null, to_id: null, point: null },
      };
    },
    methods: {
      allAccountTransfer() {
        if (this.IsLoading)
          return;

        this._setIsLoading(true);
        this.AllAccountTransfer(this.form).then((res) => {
          if (res) this.GetAccountWallet(0);
          this._setIsLoading(false);
        });
      },
      ...mapMutations(["_setIsLoading"]),
      ...mapActions("game", ["AllAccountTransfer"]),
      ...mapActions("account", ["GetAccountWallet"])
    },
    computed: {
      ...mapState(['IsLoading'])
    }
  };
</script>
