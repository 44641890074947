<template>
  <b-overlay :show="value" no-wrap>
    <template v-slot:overlay>
      <div class="loading text-center">
        <div class="lds-square"></div>
        <div class="text">Loading...</div>
      </div>
    </template>
  </b-overlay>
</template>

<script>
  export default {
    props: {
      value: { type: Boolean, default: false }
    }
  };
</script>
